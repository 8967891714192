import { batch } from "react-redux";
import api from "../utilities/api";
import { STORAGE_NOTIFICATION_KEY } from "./notification";

const user = {
  state: null,
  reducers: {
    setUser(state, userData) {
      return userData;
    },
  },
  effects: (dispatch) => ({
    async fetchUserData() {
      try {
        const data = await api.get("/user");

        const {
          competition: { competitors, ...competition },
          ...user
        } = data;

        batch(() => {
          dispatch.user.setUser(user);
          dispatch.competition.setCompetition(competition);
          dispatch.competitors.setCompetitors(competitors);
        });
      } catch (err) {
        dispatch.user.setUser(null);
      }
    },

    async login(loginToken) {
      const { accessToken } = await api.post("/login", {
        token: loginToken,
      });

      localStorage.setItem("token", accessToken);

      await this.fetchUserData();
    },

    async logout() {
      await api.post("/logout");

      localStorage.removeItem("token");
      localStorage.removeItem(STORAGE_NOTIFICATION_KEY);

      window.location.reload();
    },
  }),
};

export default user;
