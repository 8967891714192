import styled, { css } from "styled-components";
import { useState } from "react";

import { Overlay, Modal, CloseModal } from "./DeductionModal";
import { ButtonSecondary, Loader, Up } from "../../../components/Parts";
import closeIcon from "../../../assets/img/close.svg";
import arrowDown from "../../../assets/img/arrowDown.svg";
import arrowUp from "../../../assets/img/arrowUp.svg";
import api from "../../../utilities/api";
import useTranslations from "../../../hooks/useTranslations";

const SmallModal = styled(Modal)`
  max-width: 500px;
  margin-top: 160px;
`;

const Content = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  strong {
    font-weight: 500;
  }
  p {
    margin-bottom: 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: calc(50% - 8px);
  }
`;

const JudgeButton = styled(ButtonSecondary)`
  border-color: ${({ down }) => (down ? "#DB524E" : "#27AE60")};
  padding-bottom: 0;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100% !important;
    `}
`;

const StyledLoader = styled(Loader)`
  margin-top: 25px;
`;

const JudgeModal = ({ closeModal, score }) => {
  const [isLoading, setIsLoading] = useState(false);

  const translations = useTranslations();

  const isArray = Array.isArray(score);

  const notifyJudge = async (type) => {
    try {
      setIsLoading(true);
      if (isArray) {
        await api.post("/notification", {
          user_id: score[0].judge.id,
          type,
        });
        await api.post("/notification", {
          user_id: score[1].judge.id,
          type,
        });
      } else {
        await api.post("/notification", {
          user_id: score.judge.id,
          type,
        });
      }
      setIsLoading(false);
      closeModal();
    } catch (err) {
      setIsLoading(false);
      alert("An error occurred!");
    }
  };

  return (
    <Overlay>
      <SmallModal>
        <CloseModal onClick={closeModal}>
          <img src={closeIcon} alt="laf"></img>
        </CloseModal>
        {isArray ? (
          <>
            <Content>
              <p>
                {translations["Teisėjas"]}:{" "}
                <strong>{score[0].judge.name}</strong>
              </p>
              <p>
                {translations["Dabartinis balas"]}:{" "}
                <strong>{score[0].value}</strong>
              </p>
              <p>
                {translations["Dabartinė bauda"]}:{" "}
                <strong>{score[0].deduction}</strong>
              </p>
            </Content>
            <Content>
              <p>
                {translations["Teisėjas"]}:{" "}
                <strong>{score[1].judge.name}</strong>
              </p>
              <p>
                {translations["Dabartinis balas"]}:{" "}
                <strong>{score[1].value}</strong>
              </p>
              <p>
                {translations["Dabartinė bauda"]}:{" "}
                <strong>{score[1].deduction}</strong>
              </p>
            </Content>
          </>
        ) : (
          <Content>
            <p>
              {translations["Teisėjas"]}: <strong>{score.judge.name}</strong>
            </p>
            <p>
              {translations["Dabartinis balas"]}: <strong>{score.value}</strong>
            </p>
            {score.judge.positionType === "D" && (
              <p>
                {translations["Dabartinė bauda"]}:{" "}
                <strong>{score.deduction}</strong>
              </p>
            )}
          </Content>
        )}
        {isLoading ? (
          <StyledLoader />
        ) : (
          <ButtonWrapper>
            {isArray || score.judge.positionType === "D" ? (
              <>
                <JudgeButton fullWidth down onClick={() => notifyJudge("-")}>
                  <img src={arrowDown} alt="arrow down"></img>
                  <Up src={arrowUp} alt="arrow up" />
                </JudgeButton>
              </>
            ) : (
              <>
                <JudgeButton down onClick={() => notifyJudge("-")}>
                  <img src={arrowDown} alt="arrow down"></img>
                </JudgeButton>
                <JudgeButton onClick={() => notifyJudge("+")}>
                  <Up src={arrowUp} alt="arrow up" />
                </JudgeButton>
              </>
            )}
          </ButtonWrapper>
        )}
      </SmallModal>
    </Overlay>
  );
};

export default JudgeModal;
