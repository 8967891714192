import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import useTranslations from "../../../hooks/useTranslations";

const Li = styled.li`
  border: solid 2px rgba(51, 51, 51, 0.15);
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 14px;
  background-color: #fff;
  padding: 16px;
  padding-bottom: 14px;
  background: ${({ completed }) => (completed ? "#f0fdf4" : "#fff")};
  ${({ current }) =>
    current &&
    css`
      cursor: pointer;
      background-color: rgb(238, 242, 255);
      border-color: #6e41e2;
    `}
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const Group = styled.div`
  color: rgba(17, 17, 17, 0.6);
  margin: 2px 0px;
  font-size: 16px;
  line-height: 22px;
`;

const Score = styled.div`
  font-size: 18px;
  line-height: 26px;
  flex-shrink: 0;
  padding-left: 10px;
  strong {
    font-weight: 500;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Competitor = ({ competitor, setCompetitorsTable }) => {
  const currentCompetitorId = useSelector((rootState) => {
    return rootState.competitors.find((c) => c.completed === false)?.id;
  });

  const translations = useTranslations();

  const userPosition = useSelector((rootState) => rootState.user.position);

  const score = competitor.scores[userPosition]?.value.toString();
  const deduction = competitor.scores[userPosition]?.deduction.toString();

  return (
    <Li
      onClick={
        competitor.id === currentCompetitorId ? setCompetitorsTable : null
      }
      completed={competitor.completed}
      current={competitor.id === currentCompetitorId}
    >
      <Wrapper>
        <div>
          <Name>{competitor.name}</Name>
          <Group>
            {competitor.category} {competitor.group}
          </Group>
        </div>
        <Score>
          {!!score && (
            <div>
              {translations["Balas"]}: <strong>{score}</strong>
            </div>
          )}
          {!!deduction && (
            <div>
              {translations["Bauda"]}: <strong>{deduction}</strong>
            </div>
          )}
        </Score>
      </Wrapper>
    </Li>
  );
};

export default Competitor;
