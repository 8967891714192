import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  Accept: "application/json",
  "X-Requested-With": "XMLHttpRequest",
});

api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    const locale = localStorage.getItem("locale");
    if (locale) {
      config.headers["APP_LOCALE"] = locale;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  (error) => Promise.reject(error)
);

export default api;
