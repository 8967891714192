import styled from "styled-components";
import { useState } from "react";

import Spinner from "../../../components/Spinner";
import { Button } from "../../../components/Parts";
import api from "../../../utilities/api";

const StyledButton = styled(Button)`
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 48px;
  width: 58px;
`;

const ConfirmButton = ({ competitorId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    try {
      setIsLoading(true);
      await api.post(`/competitors/${competitorId}/complete`);
    } catch (err) {
      alert(err.response?.data?.message || "An Error Occurred!");
      setIsLoading(false);
    }
  };

  return (
    <StyledButton onClick={onClick} disabled={isLoading}>
      {isLoading ? <Spinner /> : "OK"}
    </StyledButton>
  );
};

export default ConfirmButton;
