import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Loader } from "../components/Parts";

const ULoader = styled(Loader)`
  margin-top: 100px;
`;

const UserLoader = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.user.fetchUserData().then(() => setLoading(false));
  }, [dispatch.user]);

  if (loading) {
    return (
      <>
        <ULoader />
      </>
    );
  }

  return <>{children}</>;
};

export default UserLoader;
