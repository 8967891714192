import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useTranslations from "../../../hooks/useTranslations";

import CompetitorRow from "./CompetitorRow";

const Table = styled.table`
  margin-top: 16px;
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  line-height: 20px;
  border-collapse: collapse;
  strong {
    font-weight: 700;
  }
  td,
  th {
    padding: 12px 8px;
    border: solid 1px #ccc;
    vertical-align: middle;
    text-align: left;
  }
`;

const Wrap = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

const Heading = styled.h3`
  font-size: 32px;
  line-height: 1;
  font-weight: 500;
  margin: 20px 0 30px;
`;

const sorterFunc = (a, b) => {
  const finalScore = (b.final_score || -100) - (a.final_score || -100);
  if (finalScore !== 0) {
    return finalScore;
  }
  const eTotal = (b.e_total_score || 0) - (a.e_total_score || 0);
  if (eTotal !== 0) {
    return eTotal;
  }
  const aTotal = (b.a_total_score || 0) - (a.a_total_score || 0);
  if (aTotal !== 0) {
    return aTotal;
  }
  const dTotal = (b.d_total_score || 0) - (a.d_total_score || 0);
  return dTotal;
};

const ChairView = () => {
  const competitors = useSelector((rootState) => rootState.competitors);

  const currentGroup = useSelector((rootState) =>
    rootState.competitors.find((c) => !c.completed)
  )?.group_with_category;

  const translations = useTranslations();

  const dispatch = useDispatch();

  const hasMoreJudges = useSelector(
    (rootState) => rootState.competition.ae_judges_count === 4
  );

  const sortedCompetitors = [...competitors]
    .filter((c) => c.group_with_category === currentGroup)
    .sort(sorterFunc);

  useEffect(() => {
    if (sortedCompetitors.length === 0) {
      dispatch.competition.setStatus(2);
    }
  }, [sortedCompetitors, dispatch.competition]);

  return (
    <Wrap>
      <Heading>
        {translations["Grupė"]}: {currentGroup}
      </Heading>
      <Table>
        <thead>
          <tr>
            <th>{translations["Vieta"]}</th>
            <th>{translations["Dalyvis"]}</th>
            <th>{translations["M1"]}</th>
            <th>{translations["M2"]}</th>
            {hasMoreJudges && (
              <>
                <th>{translations["M3"]}</th>
                <th>{translations["M4"]}</th>
              </>
            )}
            <th>
              <strong>{translations["MT"]}</strong>
            </th>
            <th>A1</th>
            <th>A2</th>
            {hasMoreJudges && (
              <>
                <th>A3</th>
                <th>A4</th>
              </>
            )}
            <th>
              <strong>AT</strong>
            </th>
            <th>{translations["S1"]}</th>
            <th>{translations["S2"]}</th>
            <th>
              <strong>{translations["S"]}+</strong>
            </th>
            <th>{translations["S"]}-</th>
            <th>L1</th>
            <th>L2</th>
            <th>{translations["TBP"]}</th>
            <th>
              <strong>{translations["Rezultatas"]}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedCompetitors.map((competitor, index) => (
            <CompetitorRow
              key={competitor.id}
              position={competitor.completed ? index + 1 : "-"}
              competitor={competitor}
            />
          ))}
        </tbody>
      </Table>
    </Wrap>
  );
};

export default ChairView;
