import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
*, body {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    &::before, &:after {
        box-sizing: border-box;
    }
}
body {
    background: #FAFAFA;
}
`;
