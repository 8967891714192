import styled, { css, keyframes } from "styled-components";
import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";

import { Button, Position, Loader } from "../../../components/Parts";
import useScoreApi from "../../../hooks/useScoreApi";
import useTranslations from "../../../hooks/useTranslations";
import Keyboard from "../../../components/Keyboard";
import Notification from "./Notification";

const pulse = keyframes`
  0% {
		transform: scale(0.95);
	}

	50% {
		transform: scale(1.6);
	}

	100% {
		transform: scale(0.95);
	}
`;

const JudgeScore = styled.strong`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  margin-left: 10px;

  > svg {
    margin-left: 15px;
    animation: ${pulse} 2s infinite;
  }
  ${({ $notification }) =>
    $notification &&
    css`
      color: red;
    `}
`;

const Scores = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const At = () => {
  const dispatch = useDispatch();

  const competitor = useSelector((rootState) =>
    rootState.competitors.find((c) => c.completed === false)
  );

  const translations = useTranslations();

  const user = useSelector((rootState) => rootState.user);

  const hasDeductionInput =
    user.positionType === "L" || user.positionType === "D";
  const hasScoreInput =
    user.positionType === "E" ||
    user.positionType === "A" ||
    user.positionType === "D";

  const notification = useSelector((rootState) => rootState.notification);

  const [value, setValue] = useState("");
  const [deduction, setDeduction] = useState("");

  const { loading, onSave } = useScoreApi(competitor.id);

  const judgeScore = competitor.scores[user.position]?.value.toString();
  const judgeDeduction = competitor.scores[user.position]?.deduction.toString();

  const [canEnterScore, setCanEnterScore] = useState(
    !judgeScore && !judgeDeduction
  );

  useEffect(() => {
    if (notification) {
      return setCanEnterScore(true);
    }
    setCanEnterScore(!judgeScore && !judgeDeduction);
  }, [judgeScore, judgeDeduction, notification]);

  const getConfirmationMessage = () => {
    switch (user.positionType) {
      case "A":
      case "E":
        return `${translations["Balas"]}: ${value || 0}\n${
          translations["Išsaugoti balą"]
        }?`;
      case "D":
        return `${translations["Balas"]}: ${value || 0}\n${
          translations["Bauda"]
        }: ${deduction || 0}\n${translations["Išsaugoti balus"]}?`;
      case "L":
        return `${translations["Bauda"]}: ${deduction || 0}\n${
          translations["Išsaugoti baudą"]
        }?`;
      default:
        return "";
    }
  };

  const onSubmit = () => {
    if (!window.confirm(getConfirmationMessage())) return;
    onSave(
      value || 0,
      (score) => {
        batch(() => {
          dispatch.competitors.updateCompetitorScore({
            competitorId: competitor.id,
            score,
          });
          dispatch.notification.setNotification("");
          setValue("");
          setDeduction("");
        });
      },
      0 - deduction || 0
    );
  };

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          <Position>
            {hasScoreInput && (
              <Scores>
                {translations["Jūsų įvertinimas"]}:
                <JudgeScore $notification={notification}>
                  <span>{judgeScore?.toString() || "-"}</span>
                  <Notification
                    notification={notification}
                    judge={user.positionType}
                  />
                </JudgeScore>
              </Scores>
            )}
            {hasDeductionInput && (
              <Scores>
                {translations["Bauda"]}:
                <JudgeScore $notification={notification}>
                  <span>{judgeDeduction || "-"}</span>
                </JudgeScore>
              </Scores>
            )}
          </Position>
          {canEnterScore && (
            <>
              <Keyboard
                value={value}
                setValue={setValue}
                deduction={deduction}
                setDeduction={setDeduction}
                hasDeductionInput={hasDeductionInput}
                hasScoreInput={hasScoreInput}
              />
              <Button type="submit" onClick={onSubmit}>
                {translations["Vertinti"]}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default At;
