import styled, { keyframes } from "styled-components";
import greenCheck from "../assets/img/greenCheck.svg";
export const Card = styled.div`
  padding: 30px 20px;
  max-width: 400px;
  margin: 10px auto 0;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  border: solid 1px rgba(51, 51, 51, 0.1);
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  strong {
    font-weight: 500;
  }
  small {
    font-size: 14px;
    line-height: 20px;
    display: block;
  }
`;

export const Button = styled.button`
  background: #6e41e2;
  border-radius: 4px;
  color: #fff;
  border: 0;
  box-shadow: 0;
  width: 100%;
  margin-top: 14px;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 18px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #5835b0;
  }
  &:active {
    background: #472c8a;
  }
`;
export const ButtonSecondary = styled(Button)`
  background: transparent;
  color: #6e41e2;
  padding: 10px 16px;
  border: solid 2px #6e41e2;
  &:hover {
    background: rgba(110, 65, 226, 0.04);
  }
  &:active {
    background: rgba(110, 65, 226, 0.16);
  }
`;

export const Input = styled.input`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 4px;
  border: solid 1px rgba(51, 51, 51, 0.1);
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding: 14px;
  margin: 0;
`;
export const DeductionHolder = styled.div`
  position: relative;
  /* margin-top: 18px; */
  ${Input} {
    padding-left: 40px;
  }
`;
export const Minus = styled.div`
  display: flex;
  background-color: rgba(51, 51, 51, 0.1);
  align-items: center;
  justify-content: center;
  position: absolute;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  left: 0;
  top: 0;
  height: 100%;
  width: 30px;
  border-right: solid 1px rgba(51, 51, 51, 0.1);
`;
export const InputHolder = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    right: 20px;
    top: calc(50% - 10px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
export const InputHolderActive = styled(InputHolder)`
  &:after {
    background-image: url(${greenCheck});
  }
  ${Input} {
    background: #cbecd9;
    box-shadow: 0px 4px 4px rgba(39, 174, 96, 0.04),
      0px 4px 40px rgba(39, 174, 96, 0.24);
    padding-right: 50px;
  }
`;

export const Name = styled.div`
  font-size: 20px;
  line-height: 26px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
`;
export const Position = styled.div`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
  margin-top: 16px;
`;
const spinner = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;
export const LoaderText = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 26px;
`;
export const Loader = styled.div`
  font-size: 10px;
  margin: 10px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #6e41e2;
  background: linear-gradient(
    to right,
    #6e41e2 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  animation: ${spinner} 1.4s infinite linear;
  transform: translateZ(0);
  &:before {
    width: 50%;
    height: 50%;
    background: #6e41e2;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  &:after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;
export const Up = styled.img`
  transform: rotate(180deg);
`;
export const Error = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #db524e;
  margin-top: 8px;
`;
