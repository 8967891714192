import { batch, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import echo from "../utilities/echo";

const useEchoListeners = () => {
  const user = useSelector((rootState) => rootState.user);
  const competitionId = useSelector((rootState) => rootState.competition?.id);

  const dispatch = useDispatch();

  useEffect(() => {
    const channel = echo
      .private(`competitions.${competitionId}`)
      .listen("CompetitionStatusChanged", () => {
        window.location.reload();
      })
      .listen("CompetitorCompleted", (competitor) => {
        batch(() => {
          dispatch.notification.setNotification("");
          dispatch.competitors.updateCompetitor(competitor);
        });
      })
      .listen("CompetitorsListChanged", () => window.location.reload());

    if (user.positionType === "C") {
      channel.listen("CompetitorScoreChanged", (competitor) => {
        dispatch.competitors.updateCompetitor(competitor);
      });
    }

    if (["E", "A", "D"].includes(user.positionType)) {
      echo
        .private(`App.Models.User.${user.id}`)
        .notification((notification) =>
          dispatch.notification.setNotification(notification.message)
        );
    }

    return () => echo.leaveChannel(channel);
  }, [user, competitionId, dispatch]);
};

export default useEchoListeners;
