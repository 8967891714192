import { useSelector } from "react-redux";
import styled from "styled-components";
import { Card, Name } from "../../../components/Parts";
import useTranslations from "../../../hooks/useTranslations";

const Scores = styled.div`
  font-size: 22px;
  line-height: 32px;
  strong {
    font-weight: 500;
  }
`;
const PreviousParticipant = () => {
  const competitor = useSelector((rootState) => {
    const completed = rootState.competitors.filter((c) => c.completed);
    return completed[completed.length - 1];
  });

  const translations = useTranslations();

  if (!competitor) return null;

  return (
    <Card>
      <h2>{translations["Praeitas Dalyvis"]}:</h2>
      <Name>
        {competitor.category} {competitor.group} <br />
        {competitor.name}
      </Name>
      <Scores>
        {translations["MT"]}: <strong>{competitor.e_total_score || "-"}</strong>{" "}
        <br />
        AT: <strong>{competitor.a_total_score || "-"}</strong> <br />
        {translations["S"]}: <strong>{competitor.d_total_score || "-"}</strong>
      </Scores>
    </Card>
  );
};

export default PreviousParticipant;
