import { useSelector } from "react-redux";
import Login from "./screens/Login";
import Competition from "./screens/Competition";

const App = () => {
  const isAuthenticated = useSelector((rootState) => !!rootState.user);

  if (isAuthenticated) {
    return <Competition />;
  }

  return <Login />;
};

export default App;
