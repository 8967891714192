import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import logoutIcon from "../../../assets/img/logout.svg";
import { ButtonSecondary } from "../../../components/Parts";
import useTranslations from "../../../hooks/useTranslations";

const Wrap = styled.div`
  background: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  font-size: 12px;
  box-shadow: 0px 1px 0px rgba(17, 17, 17, 0.1);
  border-bottom: solid 1px rgba(51, 51, 51, 0.1);
  position: relative;
  @media (max-width: 576px) {
    padding: 10px;
    flex-wrap: wrap;
    justify-content: center;
    > div {
      width: 100%;
      justify-content: center;
    }
  }
`;

const Inner = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    order: -1;
    margin-bottom: 12px;
  }
`;

const UserName = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-top: 4px;
`;

const Process = styled(ButtonSecondary)`
  width: auto;
  margin-top: 0;
  min-width: 90px;
`;

const Logout = styled.button`
  border-radius: 50%;
  border: 0;
  height: 48px;
  width: 48px;
  background: #f1f1f1;
  margin-left: 10px;
  cursor: pointer;
`;

const Lang = styled(Process)`
  margin-left: 10px;
  min-width: auto;
  text-transform: uppercase;
`;

const Header = ({ setCompetitorsTable, competitorsTable }) => {
  const dispatch = useDispatch();

  const translations = useTranslations();

  const user = useSelector((rootState) => rootState.user);
  const lang = useSelector((rootState) => rootState.language);

  const onLangChange = () => {
    dispatch.language.setLanguage(lang === "lt" ? "en" : "lt");
    dispatch.user.fetchUserData();
  };

  const onLogout = async () => {
    try {
      if (!window.confirm(translations["Ar tikrai norite atsijungti?"])) return;
      await dispatch.user.logout();
    } catch (err) {
      alert("Error! Please try again.");
    }
  };

  return (
    <Wrap>
      <div>
        {user.positionType !== "C" && (
          <Process onClick={setCompetitorsTable}>
            {!competitorsTable ? translations["Eiga"] : translations["Atgal"]}
          </Process>
        )}
        <Lang onClick={onLangChange}>{lang === "lt" ? "en" : "lt"}</Lang>
      </div>
      <Inner>
        <div>
          {user.positionLabel}
          <br />
          <UserName>{user.name}</UserName>
        </div>
        <Logout onClick={onLogout}>
          <img src={logoutIcon} alt="laf"></img>
        </Logout>
      </Inner>
    </Wrap>
  );
};

export default Header;
