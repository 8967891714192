import { useSelector } from "react-redux";
import styled from "styled-components";
import Competitor from "./Competitor";

const Ul = styled.ul`
  margin-top: 10px;
  padding: 15px;
`;

const CompetitorsTable = ({ setCompetitorsTable }) => {
  const competitors = useSelector((rootState) => rootState.competitors);

  return (
    <div>
      <Ul>
        {competitors.map((competitor) => (
          <Competitor
            key={competitor.id}
            competitor={competitor}
            setCompetitorsTable={setCompetitorsTable}
          ></Competitor>
        ))}
      </Ul>
    </div>
  );
};

export default CompetitorsTable;
