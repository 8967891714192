import { useState } from "react";
import styled, { css } from "styled-components";
import useTranslations from "../hooks/useTranslations";
import DeductionModal from "../screens/Judge/components/DeductionModal";

import { Input, DeductionHolder, Minus, ButtonSecondary } from "./Parts";

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
  margin-top: 10px;
`;

const Item = styled.div`
  flex: 0 0 33.33%;
  padding: 2px;
`;

const Button = styled.button`
  height: 60px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease-out transform, 0.1s ease-out background-color;
  border: solid 2px rgba(51, 51, 51, 0.1);
  border-radius: 4px;
  font-size: 28px;
  background: #fff;
  outline: none !important;
  &:active {
    transform: scale(0.96);
    background: rgba(51, 51, 51, 0.05);
  }
  > svg {
    width: 40px;
  }
`;

const TabsGrid = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    width: calc(50% - 2px);
  }
`;

const TabButton = styled(Button)`
  font-size: 24px;
  margin-top: 10px;
  ${({ $active }) =>
    $active &&
    css`
      border-color: #6e41e2;
    `}
`;

const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const Keyboard = ({
  value,
  setValue,
  deduction,
  setDeduction,
  hasDeductionInput,
  hasScoreInput,
}) => {
  const translations = useTranslations();

  const [showDeductionModal, setShowDeductionModal] = useState(false);

  const [activeTab, setActiveTab] = useState(hasScoreInput ? 1 : 2);

  const setter = activeTab === 1 ? setValue : setDeduction;

  const currentValue = activeTab === 1 ? value : deduction;

  const onDelete = () => {
    setter((prevValue) => prevValue.slice(0, -1));
  };

  const onValueClick = (val) => {
    setter((prevValue) => `${prevValue}${val}`);
  };

  const dotDisabled = currentValue === "" || currentValue.includes(".");

  const renderInputs = () => {
    if (hasDeductionInput && hasScoreInput) {
      return (
        <TabsGrid>
          <Input
            value={value}
            type="text"
            placeholder={translations["Įveskite balą"]}
            readOnly
          />
          <DeductionHolder>
            <Minus>-</Minus>
            <Input
              value={deduction}
              type="text"
              placeholder={translations["Įveskite baudą"]}
              readOnly
            />
          </DeductionHolder>
        </TabsGrid>
        // <Input
        //   value={value}
        //   type="text"
        //   placeholder={translations["Įveskite balą"]}
        //   readOnly
        // />
      );
    }
    if (hasDeductionInput) {
      return (
        <DeductionHolder>
          <Minus>-</Minus>
          <Input
            value={deduction}
            type="text"
            placeholder={translations["Įveskite baudą"]}
            readOnly
          />
        </DeductionHolder>
      );
    }
    return (
      <Input
        value={value}
        type="text"
        placeholder={translations["Įveskite balą"]}
        readOnly
      />
    );
  };

  return (
    <>
      {renderInputs()}
      {hasDeductionInput && hasScoreInput && (
        <TabsGrid>
          <TabButton $active={activeTab === 1} onClick={() => setActiveTab(1)}>
            {translations["Balas"]}
          </TabButton>
          <TabButton $active={activeTab === 2} onClick={() => setActiveTab(2)}>
            {translations["Bauda"]}
          </TabButton>
        </TabsGrid>
      )}
      <Grid>
        {buttons.map((btn) => (
          <Item key={btn}>
            <Button onClick={() => onValueClick(btn.toString())}>{btn}</Button>
          </Item>
        ))}
        <Item>
          <Button onClick={() => onValueClick(".")} disabled={dotDisabled}>
            .
          </Button>
        </Item>
        <Item>
          <Button onClick={() => onValueClick("0")}>0</Button>
        </Item>
        <Item>
          <Button onClick={onDelete} disabled={currentValue === ""}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="black"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"
              />
            </svg>
          </Button>
        </Item>
      </Grid>
      {/* {hasDeductionInput && hasScoreInput && (
        <>
          <DeductionHolder>
            <Minus>-</Minus>
            <Input
              value={deduction}
              type="text"
              placeholder={translations["Bauda"]}
              readOnly
            />
          </DeductionHolder>
          <ButtonSecondary onClick={() => setShowDeductionModal(true)}>
            {translations["Parinkite baudą"]}
          </ButtonSecondary>
        </>
      )}
      {showDeductionModal && (
        <DeductionModal
          closeModal={() => {
            setShowDeductionModal(false);
          }}
          setDeduction={setDeduction}
        />
      )} */}
    </>
  );
};

export default Keyboard;
