import { Card, Name } from "../../../components/Parts";
import styled from "styled-components";
import useTranslations from "../../../hooks/useTranslations";
const Inner = styled.div`
  padding: 16px;
`;
const Ended = () => {
  const translations = useTranslations();

  return (
    <Inner>
      <Card>
        <Name>{translations["Varžybos pasibaigė"]}</Name>
      </Card>
    </Inner>
  );
};

export default Ended;
