import "./utilities/polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import "reset-css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { GlobalStyle } from "./utilities/styles";
import App from "./App";
import UserLoader from "./components/UserLoader";
import store from "./store";

ReactDOM.render(
  <>
    <GlobalStyle />
    <ReduxProvider store={store}>
      <UserLoader>
        <App />
      </UserLoader>
    </ReduxProvider>
  </>,
  document.getElementById("root")
);

// if (process.env.NODE_ENV === "production") {
//   serviceWorkerRegistration.register();
// } else {
//   serviceWorkerRegistration.unregister();
// }
serviceWorkerRegistration.unregister();
