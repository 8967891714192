export const STORAGE_NOTIFICATION_KEY = "persist-storage-notifications";

const notification = {
  state: window.localStorage.getItem(STORAGE_NOTIFICATION_KEY) || "",
  reducers: {
    setNotification(state, message) {
      window.localStorage.setItem(STORAGE_NOTIFICATION_KEY, message);
      return message;
    },
  },
  effects: (dispatch) => ({}),
};

export default notification;
