import styled, { css } from "styled-components";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Button, Input, Loader, Error } from "../components/Parts";
import useTranslations from "../hooks/useTranslations";

const Heading = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 10px;
  text-align: center;
`;

const LoginCard = styled(Card)`
  margin-top: 120px;
`;

const Inner = styled.div`
  padding: 16px;
`;

const Langs = styled(Card)`
  position: fixed;
  z-index: 5;
  top: 15px;
  right: 15px;
  padding: 10px 6px;
  margin: 0;
`;

const LangButton = styled.button`
  height: 60px;
  margin: 0 5px;
  width: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease-out transform, 0.1s ease-out background-color;
  border: solid 2px rgba(51, 51, 51, 0.1);
  border-radius: 4px;
  font-size: 18px;
  background: #fff;
  outline: none !important;
  &:active {
    transform: scale(0.96);
    background: rgba(51, 51, 51, 0.05);
  }
  ${({ $active }) =>
    $active &&
    css`
      border-color: #6e41e2;
      color: #6e41e2;
    `}
`;

const Login = () => {
  const dispatch = useDispatch();
  const lang = useSelector((rootState) => rootState.language);

  const translations = useTranslations();

  const [value, setValue] = useState("");

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      await dispatch.user.login(value);
    } catch (err) {
      setLoading(false);
      setErrorMessage(err.response?.data?.message || "An error occurred!");
    }
  };

  return (
    <Inner>
      <Langs>
        <LangButton
          $active={lang === "lt"}
          onClick={() => dispatch.language.setLanguage("lt")}
        >
          LT
        </LangButton>
        <LangButton
          $active={lang === "en"}
          onClick={() => dispatch.language.setLanguage("en")}
        >
          EN
        </LangButton>
      </Langs>
      <LoginCard>
        {loading ? (
          <>
            <Loader></Loader>
          </>
        ) : (
          <form onSubmit={onSubmit}>
            <Heading>{translations["Įveskite prisijungimo kodą"]}</Heading>
            <Input
              type="text"
              placeholder={translations["Prisijungimo kodas"]}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            {errorMessage && <Error>{errorMessage}</Error>}
            <Button type="submit">{translations["Prisijungti"]}</Button>
          </form>
        )}
      </LoginCard>
    </Inner>
  );
};

export default Login;
