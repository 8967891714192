import { useSelector } from "react-redux";

import ArtExcJudge from "./ArtExcJudge";
import PreviousParticipant from "./PreviousParticipant";
import CurrentParticipant from "./CurrentParticipant";
import { Card } from "../../../components/Parts";

const JudgeView = () => {
  const hasCurrentCompetitor = useSelector(
    (rootState) => !!rootState.competitors.find((c) => c.completed === false)
  );

  return (
    <>
      <PreviousParticipant />
      {hasCurrentCompetitor && (
        <Card>
          <CurrentParticipant />
          <ArtExcJudge />
        </Card>
      )}
    </>
  );
};

export default JudgeView;
