const language = {
  state: localStorage.getItem("locale") || "lt",
  reducers: {
    setLanguage(state, lang) {
      localStorage.setItem("locale", lang);
      return lang;
    },
  },
};

export default language;
