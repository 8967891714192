import { init } from "@rematch/core";
import logger from "redux-logger";

import user from "./models/user";
import competitors from "./models/competitors";
import competition from "./models/competition";
import notification from "./models/notification";
import language from "./models/language";

const redux = {
  middlewares: [...(process.env.NODE_ENV === "development" ? [logger] : [])],
};

const store = init({
  models: { user, competitors, competition, notification, language },
  redux,
});

export default store;
