import { useState } from "react";
import api from "../utilities/api";

const useScoreApi = (competitorId) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const onSave = async (score = 0, callback, deduction = 0) => {
    try {
      setErrorMessage("");
      setLoading(true);
      const res = await api.post("/score", {
        competitor_id: competitorId,
        value: parseFloat(score),
        deduction,
      });
      callback(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage("Klaida!");
    }
  };

  return {
    error: errorMessage,
    loading,
    onSave,
  };
};

export default useScoreApi;
