import { useSelector } from "react-redux";
import lt from "../lang/lt.json";
import en from "../lang/en.json";

const translations = {
  lt,
  en,
};

const useTranslations = () => {
  const lang = useSelector((rootState) => rootState.language);

  return translations[lang];
};

export default useTranslations;
