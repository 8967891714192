import styled from "styled-components";
import { useSelector } from "react-redux";
import ChairView from "./components/ChairView";
import JudgeView from "./components/JudgeView";

const Inner = styled.div`
  padding: 15px;
`;

const Judge = () => {
  const userPositionType = useSelector(
    (rootState) => rootState.user.positionType
  );

  const renderJudgeScreen = () => {
    if (userPositionType === "C") {
      // Chair'o screen
      return <ChairView />;
    }
    // A, E, D, L screen'as
    return <JudgeView />;
  };

  return <Inner>{renderJudgeScreen()}</Inner>;
};

export default Judge;
