import styled from "styled-components";
import { keyframes } from "styled-components";

const spin = keyframes`
    to {
        transform:rotate(1turn);
    }
`;

const Svg = styled.svg`
  width: 1.25rem;
  height: 1.25rem;
  margin: auto;
  color: #fff;
  animation: ${spin} 1s linear infinite;
  path {
    opacity: 0.75;
  }
  circle {
    opacity: 0.25;
  }
`;

const Spinner = () => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </Svg>
  );
};

export default Spinner;
