import { useSelector } from "react-redux";

import { Name } from "../../../components/Parts";
import useTranslations from "../../../hooks/useTranslations";

const CurrentParticipant = () => {
  const competitor = useSelector((rootState) => {
    return rootState.competitors.find((c) => c.completed === false);
  });

  const translations = useTranslations();

  return (
    <>
      <div>{translations["Vertinamas dalyvis"]}:</div>
      <Name>
        {!!competitor.category && competitor.category + " "}
        {competitor.group}
        <br />
        {competitor.name}
      </Name>
    </>
  );
};

export default CurrentParticipant;
