import { useSelector } from "react-redux";
import Header from "./components/Header";
import Judge from "../Judge";
import NotStarted from "./components/NotStarted";
import Ended from "./components/Ended";
import CompetitorsTable from "./components/CompetitorsTable";
import styled, { css } from "styled-components";
import { useState } from "react";
import useEchoListeners from "../../hooks/useEchoListeners";

const Holder = styled.div`
  padding-top: 1px;
  min-height: calc(100vh - 79px);
  ${({ $notification }) =>
    $notification &&
    css`
      background-color: #ef4444;
    `}
`;

const Competition = () => {
  useEchoListeners();
  const notification = useSelector((rootState) => rootState.notification);
  const [showCompetitorsTable, setShowCompetitorsTable] = useState(false);

  const competitionStatus = useSelector(
    (rootState) => rootState.competition?.status
  );

  const renderCompetitionBlock = () => {
    switch (competitionStatus) {
      case 0:
        return <NotStarted />;
      case 1:
        return <Judge />;
      case 2:
        return <Ended />;
      default:
        return null;
    }
  };

  return (
    <>
      <Header
        competitorsTable={showCompetitorsTable}
        setCompetitorsTable={() =>
          setShowCompetitorsTable(!showCompetitorsTable)
        }
      />
      <Holder $notification={notification}>
        {showCompetitorsTable ? (
          <CompetitorsTable
            setCompetitorsTable={() => setShowCompetitorsTable(false)}
          />
        ) : (
          renderCompetitionBlock()
        )}
      </Holder>
    </>
  );
};

export default Competition;
