const competition = {
  state: null,
  reducers: {
    setCompetition(state, competitionData) {
      return competitionData;
    },
    setStatus(state, status) {
      return {
        ...state,
        status,
      };
    },
  },
  effects: (dispatch) => ({}),
};

export default competition;
