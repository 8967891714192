import styled, { css } from "styled-components";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import closeIcon from "../../../assets/img/close.svg";
import { Button } from "../../../components/Parts";
import Spinner from "../../../components/Spinner";
import useScoreApi from "../../../hooks/useScoreApi";
import useTranslations from "../../../hooks/useTranslations";

export const Overlay = styled.div`
  position: fixed;
  padding: 0 16px;
  left: 0;
  top: 0;
  width: 100%;
  height: 120%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const Modal = styled.div`
  background: #ffffff;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  border: solid 1px rgba(51, 51, 51, 0.1);
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
  position: relative;
  max-height: calc(100vh - 40px);
  padding-top: 80px;
`;

export const CloseModal = styled.button`
  position: absolute;
  cursor: pointer;
  background: #6e41e2;
  border-radius: 100px;
  height: 48px;
  width: 48px;
  right: 16px;
  top: 16px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Table = styled.table`
  width: 100%;
  box-shadow: 0 0 0 2px #ccc;
  border-collapse: collapse;
  border-radius: 8px;
  border-style: hidden;
  tr {
    &:nth-child(even) {
      background: rgba(0, 0, 0, 0.05);
    }
  }
  td,
  th {
    font-size: 16px;
    line-height: 20px;
    border: solid 1px #ccc;
    padding: 10px 8px;
    text-align: left;
    vertical-align: middle;
  }
  th {
    font-weight: 700;
  }
`;

const Label = styled.label`
  background-color: #fff;
  border: 1px solid grey;
  box-sizing: border-box;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

const Checkbox = styled.input`
  &:checked {
    + ${Label} {
      background-color: #6e41e2;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 10px;
        width: 8px;
        height: 16px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
`;

const Score = styled.div`
  white-space: nowrap;
  margin-right: 30px;
  font-size: 20px;
  line-height: 24px;
  strong {
    font-weight: 500;
  }
`;

const Input = styled.input`
  background-color: #fff;
  border: 1px solid grey;
  box-sizing: border-box;
  border-radius: 4px;
  height: 30px;
  width: 60px;
  text-align: center;

  ${({ $invalid }) =>
    $invalid &&
    css`
      border-color: red;
      outline-color: red;
    `}
`;

const Confirm = styled(Button)`
  min-width: 100px;
  width: auto;
  display: inline-block;
  margin-top: 0;
`;

const data = [
  { id: "A", text: "Presentation fault", deduction: -0.2 },
  { id: "B", text: "Incorrect attire", deduction: -0.2 },
  { id: "C", text: "Missing national emblem", deduction: -0.3 },
  {
    id: "D",
    text: "Time infraction (± 2 sec. of tolerance)",
    deduction: -0.2,
  },
  {
    id: "E",
    text: "Time fault (± 5 sec. of tolerance or more)",
    deduction: -0.5,
  },
  {
    id: "F",
    text: "Failure to appear on the competition area within 20 sec.",
    deduction: -0.5,
  },
  {
    id: "G",
    text: "Prohibited collaboration (pyramid height)",
    deduction: -0.5,
  },
  {
    id: "H",
    text: "Prohibited moves",
    deduction: -0.5,
  },
  {
    id: "I",
    text: "More Acrobatic Elements than number allowed in a combination (IM/IW)",
    deduction: -0.5,
  },
  {
    id: "J",
    text: "More Acrobatic Combination than sets allowed",
    deduction: -0.5,
  },
  {
    id: "K",
    text: "Repetition of Acrobatic Elements",
    deduction: -0.5,
  },
  {
    id: "L",
    text: "For IM; Acrobatic Elements landing in Split ",
    deduction: -0.5,
  },
  {
    id: "M",
    text: "MP/TR/GR: Performing different acrobatic elements",
    deduction: -0.5,
  },
  {
    id: "N",
    text: "MP/TR/GR: Not performing same acrobatic elements at the same time ",
    deduction: -0.5,
  },
  {
    id: "O",
    text: "Interruption of performance for 2-10 sec. ",
    deduction: -0.5,
  },
  {
    id: "P",
    text: "Stop of performance more than 10 sec. ",
    deduction: -2.0,
  },
  {
    id: "Q",
    text: "Themes in contravention of the Olympic Charter and the Code of Ethics",
    deduction: -2.0,
  },
];

const DeductionModal = ({ closeModal, competitorId, setDeduction }) => {
  const translations = useTranslations();

  const [checkedState, setCheckedState] = useState({});

  const userPositionType = useSelector(
    (rootState) => rootState.user.positionType
  );

  const handleOnChange = (id) => {
    setCheckedState((prev) => {
      const clone = { ...prev };

      if (prev[id] !== undefined) {
        delete clone[id];
        return clone;
      }

      clone[id] = 1;

      return clone;
    });
  };

  const handleInputChange = (id, value) => {
    setCheckedState((prev) => {
      const clone = { ...prev };

      const intVal = parseInt(value);

      clone[id] = isNaN(intVal) ? null : intVal;

      return clone;
    });
  };

  const total = useMemo(() => {
    return Object.keys(checkedState).reduce((acc, curr) => {
      const value = data.find((row) => row.id === curr).deduction;
      const sum = value * (checkedState[curr] ?? 0);

      return acc + sum;
    }, 0);
  }, [checkedState]);

  const { loading, onSave } = useScoreApi(competitorId);

  const onSaveScore = () => {
    if (userPositionType === "C") {
      onSave(0, closeModal, total.toFixed(1));
    } else {
      setDeduction(-total);
      closeModal();
    }
  };

  return (
    <Overlay>
      <Modal>
        <CloseModal onClick={closeModal}>
          <img src={closeIcon} alt="laf"></img>
        </CloseModal>
        <Table>
          <thead>
            <tr>
              <th>Co.</th>
              <th>Detail</th>
              <th>Ded.</th>
              <th>Sel.</th>
              <th>Qnt.</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ id, text, deduction }, index) => {
              return (
                <tr key={id}>
                  <td>{id}</td>
                  <td>{text}</td>
                  <td>{deduction}</td>
                  <td>
                    <Checkbox
                      checked={checkedState[id] !== undefined}
                      onChange={() => handleOnChange(id)}
                      id={id}
                      hidden
                      type="checkbox"
                      name={id}
                      value={id}
                    />
                    <Label htmlFor={id}></Label>
                  </td>
                  <td>
                    <Input
                      $invalid={checkedState[id] === null}
                      type="number"
                      step="1"
                      min="1"
                      disabled={checkedState[id] === undefined}
                      onChange={(e) => handleInputChange(id, e.target.value)}
                      value={checkedState[id] ?? ""}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Row>
          <Score>
            {translations["Viso"]}: <strong>{total.toFixed(1)}</strong>
          </Score>
          <Confirm disabled={loading} onClick={onSaveScore}>
            {loading ? <Spinner /> : "OK"}
          </Confirm>
        </Row>
      </Modal>
    </Overlay>
  );
};
export default DeductionModal;
