const competitors = {
  state: [],
  reducers: {
    setCompetitors(state, payload) {
      return payload;
    },
    updateCompetitor(state, updatedCompetitor) {
      return state.map((competitor) => {
        return competitor.id === updatedCompetitor.id
          ? updatedCompetitor
          : competitor;
      });
    },
    updateCompetitorScore(state, { score, competitorId }) {
      return state.map((competitor) => {
        return competitor.id === competitorId
          ? {
              ...competitor,
              scores: {
                ...competitor.scores,
                [Object.keys(score)[0]]: score[Object.keys(score)[0]],
              },
            }
          : competitor;
      });
    },
  },
  effects: (dispatch) => ({}),
};

export default competitors;
